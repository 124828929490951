.pagination__container {
    display: flex;
    align-items: center;
  }
  
  .pagination__button {
    padding: 5px;
    background-color: #5a9d6e;
    border: 1px solid #2d445f;
    cursor: pointer;
    border-radius: 3px;
    margin: 0 5px;
  }
  
  .pagination__button:hover {
    background-color: #4e8c61;
  }
  
  .pagination__button--disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #616b62;
  }
  
  .pagination__page-active {
    display: flex;
    align-items: center;
    text-align: center;
    min-width: 50px;
  }
  
  .pagination__current-page {
    display: block;
    width: 15px;
    margin-right: 5px;
  }